
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.OverlayLink {
  color: inherit;
  text-decoration: inherit;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.debug {
  &::after {
    background: lime;
    opacity: 0.4;
  }
}
