
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$CardGrid-toggleWidth: $breakpoint-tabletLandscape - 56px;

.CardGroup {
  white-space: nowrap;

  @include mq($breakpoint-tabletLandscape) {
    white-space: normal;
  }
}

.CardGroupInner {
  padding-top: spacing(2);
}

.CardGroupList {
  display: flex;
  padding-left: spacing(3);
  padding-right: spacing(3);

  @include mq($max: $CardGrid-toggleWidth - 1) {
    width: min-content;
  }

  // Non-grid fallback
  & > * {
    flex: 1 0 spacing(26);
    padding-left: spacing(2);
    padding-right: spacing(2);
  }

  @supports (display: grid) {
    display: grid;
    grid-gap: spacing(2);

    @include mq($max: $CardGrid-toggleWidth - 1) {
      grid-auto-flow: column;
      grid-auto-columns: spacing(26);
    }

    @include mq($CardGrid-toggleWidth) {
      > li {
        margin: 0;
        grid-column: span 2;
      }
    }

    @include mq($CardGrid-toggleWidth, $max: $breakpoint-desktop - 1) {
      grid-template-columns: repeat(4, 1fr);

      // Center one trailing item in a row of two
      > li:last-child:nth-child(2n - 1) {
        grid-column-end: 4;
      }
    }

    @include mq($breakpoint-tabletLandscape) {
      grid-gap: spacing(6);
    }

    @include mq($breakpoint-desktop) {
      grid-template-columns: repeat(6, 1fr);

      // Center two trailing items in a row of three
      > li:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }
      > li:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      // Center one trailing item in a row of three
      > li:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }

    @include mq($breakpoint-desktopMedium) {
      grid-gap: spacing(8);
    }

    // Non-grid fallback
    & > * {
      flex: none;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
