
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CardGridList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: spacing(-2);

  > li {
    flex: 0 1 320px;
    margin: spacing(2);
  }

  // Using Grid to allow for better `gap`. To allow centering of two trailing
  // items in a row of three, the grid is set up with double the amount of
  // expected columns – two per item.

  @supports (display: grid) {
    display: grid;
    grid-gap: spacing(2);
    margin: 0;

    @include mq($breakpoint-mobileLandscape, $max: $breakpoint-desktop - 1) {
      grid-template-columns: repeat(4, 1fr);

      // Center one trailing item in a row of two
      > li:last-child:nth-child(2n - 1) {
        grid-column-end: 4;
      }
    }

    @include mq($breakpoint-tabletLandscape) {
      grid-gap: spacing(6);
    }

    @include mq($breakpoint-desktop) {
      grid-template-columns: repeat(6, 1fr);

      // Center two trailing items in a row of three
      > li:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }
      > li:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      // Center one trailing item in a row of three
      > li:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }

    @include mq($breakpoint-desktopMedium) {
      // Tweaked specifically to allow images to be exactly 310px when viewed on desktop
      grid-gap: spacing(8) - 5px;
    }

    > li {
      margin: 0;
      grid-column: span 2;
    }
  }
}
