
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SplitSummary {
  @include mq($breakpoint-tabletLandscape) {
    display: flex;
  }
}

.SplitSummaryImage {
  max-width: 400px;
  margin: 0 auto spacing(3);
  transition: all $duration-short $easing-bounce;

  @include mq($breakpoint-tabletLandscape) {
    max-width: none;
    flex: 1 1 60%;
    margin: 0;
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-right: spacing(6);
    > * {
      width: 100%;
      position: sticky;
      top: 0;
    }

    .reverse & {
      order: 1;
      margin-right: 0;
      margin-left: spacing(6);
    }
  }

  @include mq($breakpoint-desktop) {
    margin-right: spacing(8);

    .reverse & {
      order: 1;
      margin-right: 0;
      margin-left: spacing(8);
    }
  }
}

.clickable {
  text-decoration: none;

  .SplitSummaryImage {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: box-shadow $duration-short $easing-bounce;
      box-shadow: 0 0 0 0 inset $color-green, 0 0 0 0 $color-green;
      @supports (--css: variables) {
        box-shadow: 0 0 0 0 inset var(--color-section-accent, $color-green),
          0 0 0 0 var(--color-section-accent, $color-green);
      }
      @include corners($crop: false);
    }
  }

  &:hover .SplitSummaryImage,
  &:focus .SplitSummaryImage,
  &:focus-within .SplitSummaryImage {
    outline: 0;

    &::after {
      box-shadow: 0 0 0 spacing(0.25) inset $color-green,
        0 0 0 spacing(0.25) $color-green;
      @supports (--css: variables) {
        box-shadow: 0 0 0 (0.25) inset var(--color-section-accent, $color-green),
          0 0 0 spacing(0.25) var(--color-section-accent, $color-green);
      }
      @include mq($breakpoint-desktop) {
        box-shadow: 0 0 0 spacing(0.5) inset $color-green,
          0 0 0 spacing(0.5) $color-green;
        @supports (--css: variables) {
          box-shadow: 0 0 0 spacing(0.5) inset
              var(--color-section-accent, $color-green),
            0 0 0 spacing(0.5) var(--color-section-accent, $color-green);
        }
      }
    }
  }

  &:hover .SplitSummaryImage {
    transform: scale(1.03) rotate(2deg);
  }

  &:active .SplitSummaryImage {
    transform: scale(0.99) rotate(2deg);
    transition-duration: 0.1s;
  }

  &:hover {
    .Heading {
      text-decoration: underline;
    }
  }

  .SplitSummaryContent {
    p {
      color: $color-black;
    }
  }
}

.SplitSummaryContent {
  @include mq($breakpoint-tabletLandscape) {
    flex: 1 0 40%;
    min-width: 300px;
    align-self: center;
  }
}

.reverse {
  .SplitSummaryImage {
    order: 1;
  }
}
