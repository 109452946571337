@mixin corners($crop: true) {
  @if $crop {
    overflow: hidden;
  }

  border-radius: spacing(1);

  @include mq($breakpoint-tablet) {
    border-radius: spacing(2);
  }
}
